import "core-js/stable";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { i18n } from "@/i18n";
import filters from "@/service/Filters";
import { ComponentLibrary, settings } from "@xsenscloud/xse-styleguide";
import Authentication, { forEnv } from "@xsenscloud/vue-authentication";
import VueAppInsights from "vue-application-insights";
import * as Sentry from "@sentry/vue";

import "@/service/ApiService";
import { EnvironmentConfig } from "@xsenscloud/vue-authentication/types/plugin";

if (settings.APPINSIGHTS_INSTRUMENTATIONKEY) {
  Vue.use(VueAppInsights, {
    id: settings.APPINSIGHTS_INSTRUMENTATIONKEY,
    router,
  });
}

if (settings.SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: settings.SENTRY_DSN as string,
    logErrors: true,
    environment: (settings.ENVIRONMENT as string) || "development",
  });
}

Vue.config.devtools = settings.ENVIRONMENT !== "production";
Vue.config.productionTip = false;

Vue.use(ComponentLibrary);
Vue.use(filters);
Vue.use(Authentication, {
  configuration: forEnv((settings as unknown) as EnvironmentConfig),
  store,
});

async function main() {
  const vm = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  });
  vm.$mount("#app");
}

main();
