import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { SignInView } from "@xsenscloud/xse-styleguide";
import BaseLayout from "@/views/layout/Base.vue";
import NotFound from "@/views/NotFound.vue";
import {
  redirectWhenUnauthenticated,
  redirectWhenAuthenticated,
} from "@/service/guards";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/organization",
    component: BaseLayout,
    beforeEnter: redirectWhenUnauthenticated("error", ["system_admin"]),
    children: [
      {
        path: "overview",
        name: "organization-overview",
        component: () =>
          import(
            /* webpackChunkName: "organization" */ "../views/organization/Overview.vue"
          ),
      },
    ],
  },

  {
    path: "/users",
    component: BaseLayout,
    beforeEnter: redirectWhenUnauthenticated("error", [
      "organization_admin",
      "system_admin",
    ]),
    children: [
      {
        path: "overview",
        name: "users-overview",
        component: () =>
          import(/* webpackChunkName: "users" */ "../views/users/Overview.vue"),
      },
    ],
  },
  {
    path: "/",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "login",
        props: {
          nextRoute: "users-overview",
        },
        component: SignInView,
        beforeEnter: redirectWhenAuthenticated("users-overview"),
      },
      {
        path: "/error",
        name: "error",
        props: true,
        component: () =>
          import(/* webpackChunkName: "error" */ "../views/Error.vue"),
      },
      {
        path: "*",
        component: NotFound,
      },
    ],
  },
];

const router = new VueRouter({ routes });

export default router;
