<template>
  <div class="page">
    <page-header />
    <site-menu v-if="isAuthenticated" />
    <div class="content-container container">
      <router-view />
    </div>
    <site-footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/common/PageHeader.vue";
import SiteMenu from "@/components/common/SiteMenu.vue";
import { namespace } from "vuex-class";

const authModule = namespace("auth");

@Component({
  components: { PageHeader, SiteMenu },
})
export default class BaseLayout extends Vue {
  @authModule.Getter("isAuthenticated")
  isAuthenticated!: boolean;
}
</script>

<style lang="scss" scoped>
.page {
  background: $white;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  .content-container {
    flex-grow: 2;
    padding-bottom: 110px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 230px;
    }
  }
}
</style>
