import { VueConstructor } from "vue/types/umd";
import { formatDate, formatNumber, formatTime } from "@/util/Time";

export default {
  install(Vue: VueConstructor) {
    Vue.filter("formatNumber", function (value: any, format: any) {
      return formatNumber(value, format);
    });

    Vue.filter("formatDate", function (value: any, format: any) {
      return formatDate(value, format);
    });

    Vue.filter("formatTime", function (value: any) {
      return formatTime(value);
    });
  },
};
