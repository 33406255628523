import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AzureB2CService } from "@xsenscloud/vue-authentication";
import store from "@/store";

@Module({ namespaced: true })
class AuthModule extends VuexModule {
  authenticated = false;
  isRefreshing = false;
  refreshingCall: Function | null = null;

  get isAuthenticated() {
    return this.authenticated;
  }

  @Mutation
  clearState() {
    this.authenticated = false;
  }

  @Mutation
  setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  @Mutation
  setRefreshingState(refreshing: boolean) {
    this.isRefreshing = refreshing;
  }

  @Mutation
  setRefreshingCall(call: Function) {
    this.refreshingCall = call;
  }

  /**
   * delete token and reset state
   * @param commit
   */
  @Action
  logout() {
    this.context.commit("clearState");
    store.dispatch("user/logout").then(() => {
      AzureB2CService.logout();
    });
  }

  @Action
  refresh() {
    this.context.commit("setAuthenticated", false);
    store.dispatch("user/logout");
  }
}

export default AuthModule;
