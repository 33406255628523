import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  settings,
  Account,
  Configuration,
  Permission,
} from "@xsenscloud/xse-styleguide";

const blankAccount: Account = {
  id: -1,
  email: "",
  name: "",
  sub: "",
  tenant: "",
  organization: "",
  avatar: "",
  token: null,
  roles: {
    /* eslint-disable @typescript-eslint/camelcase */
    system_admin: false,
    /* eslint-disable @typescript-eslint/camelcase */
    organization_admin: false,
  },
  permissions: [],
};

export function canAccessConfiguration(
  config: Configuration,
  account: Account
) {
  // When no permissons are required this always true
  if (config.permissions.length === 0) {
    return true;
  }

  // check if all the required permissions are met
  const hasPermissions = config.permissions.reduce(
    (granted: boolean, permission: Permission) => {
      return account.permissions.indexOf(permission) !== -1 && granted;
    },
    true
  );

  // Check if one or more of the required roles are met
  const hasRole =
    (!config.roles.system_admin && !config.roles.organization_admin) ||
    (config.roles.system_admin && account.roles.system_admin) ||
    (config.roles.organization_admin && account.roles.organization_admin);
  return hasPermissions && hasRole;
}

@Module({ namespaced: true, preserveState: true })
class UserModule extends VuexModule {
  public account: Account = blankAccount;
  configuration: Configuration[] = [];

  get sub() {
    return this.account.sub;
  }

  get owner() {
    return this.account.id;
  }

  get portals() {
    return ((settings.APPLICATIONS as unknown) as Configuration[])
      .filter((config) => canAccessConfiguration(config, this.account))
      .filter((config) => config.type === "portal");
  }

  get portalHome() {
    return ((settings.APPLICATIONS as unknown) as Configuration[]).find(
      (config) => config.type === "portal-home"
    );
  }

  get isSystemAdmin(): boolean {
    return this.account.roles.system_admin;
  }

  get isOrganizationAdmin() {
    return this.account.roles.organization_admin;
  }

  get applications() {
    return this.configuration.filter((config) =>
      canAccessConfiguration(config, this.account)
    );
  }

  @Mutation
  clearState() {
    this.account = {
      ...blankAccount,
      token: "",
    };
  }

  @Mutation
  setAccount(user: Account) {
    this.account = {
      ...this.account,
      ...user,
    };
  }

  @Mutation
  setConfiguration(configuration: Configuration[]) {
    this.configuration = configuration;
  }

  @Action
  currentAccountId() {
    // implement user provisioning here for cloud reporting portal
  }

  /**
   * delete token and reset state
   */
  @Action({ commit: "clearState" })
  logout() {
    //
  }
}

export default UserModule;
