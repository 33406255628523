import store from "@/store";
import { NavigationGuardNext, Route } from "vue-router";

function hasRole(roles: string[]): boolean {
  return (
    roles
      .map((role) => {
        switch (role) {
          case "system_admin":
            return store.getters["user/isSystemAdmin"];
          case "organization_admin":
            return store.getters["user/isOrganizationAdmin"];
        }
      })
      .filter((isRole) => isRole === true).length > 0
  );
}

/**
 * Redirect forms when not authenticated
 * @param name
 * @param roles
 */
export const redirectWhenUnauthenticated = (name: string, roles: string[]) => (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  if (!isAuthenticated || !hasRole(roles)) {
    next({ name });
  } else {
    next();
  }
};

/**
 * Redirect forms when authenticated
 * @param name
 */
export const redirectWhenAuthenticated = (name: string) => (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  if (store.getters["auth/isAuthenticated"]) {
    next({ name });
  } else {
    next();
  }
};
