import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import user from "@/store/modules/user";
import auth from "@/store/modules/auth";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    user,
    auth,
  },
  strict: debug,
  plugins: [new VuexPersistence({ modules: ["user", "auth"] }).plugin],
});

export default store;
