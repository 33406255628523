<template>
  <action-bar @logoAction="home()" :user-data="userData">
    <template #title>
      {{ title }}
    </template>
    <template #dropdown-user>
      <div>
        <ul>
          <li v-if="!isAuthenticated">
            <router-link :to="{ name: 'login' }">{{
              $t("profile.menu.login")
            }}</router-link>
          </li>
          <li v-if="isAuthenticated">
            <a href="#" @click="doLogout()">{{ $t("profile.menu.logout") }}</a>
          </li>
        </ul>
        <hr>
        <label>{{ $t("app.language") }}</label>
        <multiselect
          v-model="$i18n.locale"
          :options="langs"
          :showLabels="false"
          :placeholder="$t('app.language_placeholder')"
        ></multiselect>
      </div>
    </template>
    <template #dropdown-apps>
      <div v-if="isAuthenticated">
        <ul>
          <li v-for="app in portals" :key="app.label">
            <a
              :href="app.url"
              :class="{ bold: isCurrent(app.label) }"
              :title="$t(app.label)"
              >{{ $t(app.label) }}</a
            >
          </li>
        </ul>
      </div>
    </template>
  </action-bar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { translate } from "@/i18n";
import { Account, Configuration } from "@xsenscloud/xse-styleguide";

const user = namespace("user");
const auth = namespace("auth");

@Component
export default class PageHeader extends Vue {
  @Prop({ default: translate("app.title") })
  readonly title!: string;

  public langs: Array<string> = ["en-US", "nl-NL"];

  @auth.Getter
  public isAuthenticated!: boolean;

  @user.State
  public account!: Account;

  @user.Getter
  public portals!: Configuration[];

  @user.Getter
  public portalHome!: Configuration;

  get userData() {
    return this.isAuthenticated ? this.account : {};
  }

  private home() {
    window.location.href = this.portalHome.url;
  }

  private doLogout() {
    this.$store.dispatch("auth/logout");
  }

  isCurrent(label: string) {
    return label.includes("user-management");
  }
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
